'use client';

import React, { useCallback, useRef, useState } from 'react';
import { useAPI } from '@/utils/api/provider';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { Star, ClockArrowDown, User } from 'lucide-react';
import { useUser } from '@/hooks/useUser';
import { useSearchParams } from 'next/navigation';
import { WebsiteCard } from '../WebsiteCard';
import { useQuery } from '@tanstack/react-query';
import { WebsiteList } from '.';
import { Website } from '@repo/common/entities'; // 确保导入 Website 类型

export const LatestWebsites: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  const api = useAPI();
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status
  } = useInfiniteQuery({
    queryKey: ['latestWebsite'],
    queryFn: ({
      pageParam
    }: {
      pageParam?: string;
    }) => api.getLatestWebsites({
      cursor: pageParam
    }),
    getNextPageParam: lastPage => lastPage.nextCursor,
    initialPageParam: undefined as string | undefined
  });
  return <div className={className} data-sentry-component="LatestWebsites" data-sentry-source-file="discover.tsx">
      {isLoading && <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500"></div>
        </div>}
      {data && <>
          <WebsiteList websites={data.pages.flatMap(page => page.data)} />
          {hasNextPage && <button onClick={() => fetchNextPage()} disabled={isFetchingNextPage} className="mt-4 px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded">
              {isFetchingNextPage ? 'Loading...' : 'Load More'}
            </button>}
        </>}
    </div>;
};
export const MineWebsites: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  const api = useAPI();
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status
  } = useInfiniteQuery({
    queryKey: ['mineWebsite'],
    queryFn: ({
      pageParam
    }: {
      pageParam?: string;
    }) => api.getMineWebsites({
      cursor: pageParam
    }),
    getNextPageParam: lastPage => lastPage.nextCursor,
    initialPageParam: undefined as string | undefined
  });
  return <div className={className} data-sentry-component="MineWebsites" data-sentry-source-file="discover.tsx">
      {isLoading && <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500"></div>
        </div>}
      {data && <>
          <WebsiteList websites={data.pages.flatMap(page => page.data)} />
          {hasNextPage && <button onClick={() => fetchNextPage()} disabled={isFetchingNextPage} className="mt-4 px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded">
              {isFetchingNextPage ? 'Loading...' : 'Load More'}
            </button>}
        </>}
    </div>;
};
export const UserWebsites: React.FC<{
  className?: string;
  userId: string;
}> = ({
  className,
  userId
}) => {
  const api = useAPI();
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status
  } = useInfiniteQuery({
    queryKey: ['userWebsite', userId],
    queryFn: ({
      pageParam
    }: {
      pageParam?: string;
    }) => api.getLatestWebsites({
      cursor: pageParam,
      userId
    }),
    getNextPageParam: lastPage => lastPage.nextCursor,
    initialPageParam: undefined as string | undefined
  });
  return <div className={className} data-sentry-component="UserWebsites" data-sentry-source-file="discover.tsx">
      {isLoading && <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500"></div>
        </div>}
      {data && <>
          <WebsiteList websites={data.pages.flatMap(page => page.data)} />
          {hasNextPage && <button onClick={() => fetchNextPage()} disabled={isFetchingNextPage} className="mt-4 px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded">
              {isFetchingNextPage ? 'Loading...' : 'Load More'}
            </button>}
        </>}
    </div>;
};
export const DiscoverWebsites: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  const {
    hasLogin
  } = useUser();
  return <div className={className} data-sentry-component="DiscoverWebsites" data-sentry-source-file="discover.tsx">
      <Tabs defaultValue="recommended" className="w-full" data-sentry-element="Tabs" data-sentry-source-file="discover.tsx">
        <TabsList className="w-fit max-w-sm flex gap-1" data-sentry-element="TabsList" data-sentry-source-file="discover.tsx">
          <TabsTrigger className="inline-flex px-4 gap-2 w-fit hover:bg-white/50" value="recommended" data-sentry-element="TabsTrigger" data-sentry-source-file="discover.tsx">
            <Star size={16} data-sentry-element="Star" data-sentry-source-file="discover.tsx" /> <span>Recommended</span>
          </TabsTrigger>
          <TabsTrigger className="inline-flex px-4 gap-2 w-fit hover:bg-white/50" value="latest" data-sentry-element="TabsTrigger" data-sentry-source-file="discover.tsx">
            <ClockArrowDown size={16} data-sentry-element="ClockArrowDown" data-sentry-source-file="discover.tsx" /> <span>Latest</span>
          </TabsTrigger>
          {hasLogin && <TabsTrigger className="inline-flex px-4 gap-2 w-fit hover:bg-white/50" value="mine">
              <User size={16} /> <span>Mine</span>
            </TabsTrigger>}
        </TabsList>
        <div className="my-8">
          <TabsContent value="recommended" data-sentry-element="TabsContent" data-sentry-source-file="discover.tsx">
            <RecommendedWebsites data-sentry-element="RecommendedWebsites" data-sentry-source-file="discover.tsx" />
          </TabsContent>
          <TabsContent value="latest" data-sentry-element="TabsContent" data-sentry-source-file="discover.tsx">
            <LatestWebsites data-sentry-element="LatestWebsites" data-sentry-source-file="discover.tsx" />
          </TabsContent>
          {hasLogin && <TabsContent value="mine">
              <MineWebsites />
            </TabsContent>}
        </div>
      </Tabs>
    </div>;
};
export const WebsitesSection: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  const uid = useSearchParams().get('uid');
  if (!uid) {
    return <DiscoverWebsites className={className} />;
  }
  return <UserWebsites className={className} userId={uid} data-sentry-element="UserWebsites" data-sentry-component="WebsitesSection" data-sentry-source-file="discover.tsx" />;
};
export const RecommendedWebsites: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  const api = useAPI();
  const [websites, setWebsites] = useState<Website[]>([]); // 明确指定类型
  const [isFetching, setIsFetching] = useState(false);
  const fetchWebsites = async () => {
    setIsFetching(true);
    const newWebsites = await api.getRecommendedWebsites({
      did: localStorage.getItem('user_did') || undefined
    });
    setWebsites(prevWebsites => [...prevWebsites, ...newWebsites]);
    setIsFetching(false);
  };
  const {
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['recommendedWebsite'],
    queryFn: fetchWebsites,
    refetchOnWindowFocus: false
  });
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback((node: HTMLAnchorElement) => {
    if (isFetching) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        refetch();
      }
    });
    if (node) observer.current.observe(node);
  }, [isFetching, refetch]);
  return <div className={className} data-sentry-component="RecommendedWebsites" data-sentry-source-file="discover.tsx">
      {isLoading && <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500"></div>
        </div>}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-2 md:gap-3 lg:gap-4">
        {websites.map((website, index) => <WebsiteCard key={`${website.id}-${index}`} website={website} ref={index === websites.length - 1 ? lastElementRef : undefined} />)}
      </div>
      {isFetching && <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500"></div>
        </div>}
    </div>;
};